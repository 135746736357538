import React from 'react'
import { useParams } from 'react-router-dom'

// Styled Elements
import {
  CasesSubHeaderWrapper,
  CasesSubHeaderNavList,
  CasesSubHeaderNavListItem,
  CasesSubHeaderNavListLink,
} from './AlertsSubHeader.elements'

function AlertsSubHeader() {
  // Hooks
  const { customerId } = useParams()

  return (
    <CasesSubHeaderWrapper>
      <CasesSubHeaderNavList>
        <CasesSubHeaderNavListItem>
          <CasesSubHeaderNavListLink exact activeClassName="active" to="/alerts">
            Alerts
          </CasesSubHeaderNavListLink>
        </CasesSubHeaderNavListItem>
        <CasesSubHeaderNavListItem>
          <CasesSubHeaderNavListLink exact activeClassName="active" to="/alerts/policies">
            Policies
          </CasesSubHeaderNavListLink>
        </CasesSubHeaderNavListItem>
      </CasesSubHeaderNavList>
    </CasesSubHeaderWrapper>
  )
}

export default AlertsSubHeader
