import React from 'react'
import PropTypes from 'prop-types'
import { useField, ErrorMessage } from 'formik'

// Styled Elements
import { FormInputWrapper, FormLabel, FormTextFieldInput, FormErrorMessage } from './FormTextField.elements'

function FormTextField(props) {
  // Destructure
  const { type, label, ...rest } = props

  // Hooks
  const [fields] = useField(props)

  return (
    <FormInputWrapper>
      {label && <FormLabel>{label}</FormLabel>}
      <FormTextFieldInput type={type} {...fields} {...rest} />
      <FormErrorMessage>
        <ErrorMessage {...fields} />
      </FormErrorMessage>
    </FormInputWrapper>
  )
}

// Default Props
FormTextField.defaultProps = {
  type: '',
  label: '',
}

// Proptypes Validation
FormTextField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
}

export default FormTextField
