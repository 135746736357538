import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton } from './WhiteListingFilterModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

import { CaseManagementTableFilterForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function WhiteListingFilterModal(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isWhiteListingFilterModalOpen } = ui

  // Store Actions
  const { toggleWhiteListingFilterModal } = actions

  // Functions
  const toggleModal = () => {
    toggleWhiteListingFilterModal()
  }

  const handleOnTableFilterChange = (params) => {}

  return (
    <Modal isOpen={isWhiteListingFilterModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        WhiteListing Filters
      </ModalHeader>
      <ModalBody>
        <ModalBodyWrapper>
          <CaseManagementTableFilterForm onChange={handleOnTableFilterChange} />
        </ModalBodyWrapper>
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton>
            <Button color="primary" onClick={toggleModal}>
              Apply Filters
            </Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
WhiteListingFilterModal.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
WhiteListingFilterModal.propTypes = {
  ui: PropTypes.shape({ isWhiteListingFilterModalOpen: PropTypes.bool }),
  actions: PropTypes.shape({
    toggleWhiteListingFilterModal: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(WhiteListingFilterModal)
