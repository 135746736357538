import React from 'react'

import {
  Alerts,
  AppSidebarDrawer,
  AssignToCaseDrawer,
  CreateIndividualCaseDrawer,
  CreateEntityCaseDrawer,
  DeleteAddressModal,
  CasesManagementFilterModal,
  UploadCaseModal,
  ChooseKYCUploadModal,
} from 'views/layouts'

function Global() {
  return (
    <>
      {/* Alerts */}
      <Alerts />

      {/* Drawers */}
      <AppSidebarDrawer />
      <AssignToCaseDrawer />
      <CreateIndividualCaseDrawer />
      <CreateEntityCaseDrawer />

      {/* Modals */}
      <DeleteAddressModal />
      <CasesManagementFilterModal />
      <UploadCaseModal />
      <ChooseKYCUploadModal />
    </>
  )
}

export default Global
