import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Assets
import UserIcon from 'assets/images/user.svg'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useFetchUserProfile } from 'core/hooks/api'

// Styled Components
import { UserProfileWrapper, UserProfileTabsWrapper, TabHeaderIcon } from './UserProfile.elements'

// Views
import { Container, Tabs, TabPane, Image, Button } from 'views/components'
import {
  App,
  Header,
  Sidebar,
  Content,
  ProfilePersonalInformationForm,
  ProfileAccountInformationForm,
  ProfileOrganizationDetailsForm,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function UserProfile() {
  // Refs
  const formRef = useRef()

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const { userProfileData, getUserProfile } = useFetchUserProfile()

  // Functions
  const handleButtonClickSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm()
    }
  }

  // useEffect
  useEffect(() => {
    getUserProfile({ user_id: userCredentials.User_ID })
  }, [])

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          <Container>
            <UserProfileWrapper>
              <UserProfileTabsWrapper>
                {userProfileData && (
                  <Tabs
                    type="detached"
                    title="Manage Your Profile"
                    titleIcon={
                      <TabHeaderIcon>
                        <Image src={UserIcon} />
                      </TabHeaderIcon>
                    }
                    tabs={[
                      {
                        id: 1,
                        name: 'Profile Information',
                        footer: <Button onClick={handleButtonClickSubmit}>Save</Button>,
                      },
                      { id: 2, name: 'Account Information' },
                      { id: 3, name: 'Organization Details' },
                    ]}
                  >
                    <TabPane id="1">
                      <ProfilePersonalInformationForm formRef={formRef} userProfileData={userProfileData} />
                    </TabPane>
                    <TabPane id="2">
                      <ProfileAccountInformationForm userProfileData={userProfileData} />
                    </TabPane>
                    <TabPane id="3">
                      <ProfileOrganizationDetailsForm userProfileData={userProfileData} />
                    </TabPane>
                  </Tabs>
                )}
              </UserProfileTabsWrapper>
            </UserProfileWrapper>
          </Container>
        </Content>
      </Layout>
    </App>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
