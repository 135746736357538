import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import WaveIcon from 'assets/images/icon-1.svg'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import {
  useFetchDailyAlerts,
  useFetchAlertsBySeverity,
  useFetchAlertsByStatus,
  useFetchAllAlerts
} from 'core/hooks/api'

// Views
import { Container, Image, AlertBreakDownBoxes } from 'views/components'
import {
  App,
  Header,
  Sidebar,
  Content,
  AlertsTable,
  ActivityChart,
  SeverityChart,
  AlertsSubHeader,
  LoadingPage,
  NoAccess
} from 'views/layouts'

// Styled Components
import {
  PageWrapper,
  AlertsWrapper,
  AlertsBreakdownWrapper,
  AlertsBreakdownTableWrapper,
  AlertsBreakdownColWrapper,
  AlertsBreakdownRowWrapper,
} from './Alerts.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function Alerts(props) {
  // Destructure
  const { actions, filters, auth } = props

  // Store State
  const { caseManagementFilters } = filters
  const { caseType, caseStatus } = caseManagementFilters

  // Store Actions
  const { setActiveModule, setShowHeaderLoader, showAlert, updateAccessToFeatures } = actions

  // States
  const [breakdownSummaryData, setBreakdownSummaryData] = useState({
    dailyAlertsData: {}, alertsBySeverityData: [], alertsByStatusData: {}
  })
  const [allAlertsDataState, setAllAlertsDataState] = useState([{}])

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const {
    getDailyAlerts,
    dailyAlertsData,
    isDailyAlertsLoading,
    isDailyAlertsError,
  } = useFetchDailyAlerts()
  const {
    getAlertsBySeverity,
    alertsBySeverityData,
    isAlertsBySeverityLoading,
    isAlertsBySeverityError,
  } = useFetchAlertsBySeverity()
  const {
    getAlertsByStatus,
    alertsByStatusData,
    isAlertsByStatusLoading,
    isAlertsByStatusError,
  } = useFetchAlertsByStatus()
  const {
    getAllAlerts,
    allAlertsData,
    isAllAlertsLoading,
    isAllAlertsError
  } = useFetchAllAlerts()

  // Variables
  const isLoading = (isDailyAlertsLoading || isAlertsBySeverityLoading || isAlertsByStatusLoading || isAllAlertsLoading)
  const notLoading = (!isDailyAlertsLoading && !isAlertsBySeverityLoading && !isAlertsByStatusLoading && !isAllAlertsLoading)
  const rececivedData = (!!dailyAlertsData || !!alertsBySeverityData || !!alertsByStatusData || !!allAlertsData)
  const isError = (isDailyAlertsError || isAlertsBySeverityError || isAlertsByStatusError || isAllAlertsError)

  // Functions
  const fetchData = () => {
    getAlertsByStatus({ biz_id: userCredentials.Business_ID, case_type_id: caseType, status_id: caseStatus })
    getAlertsBySeverity({ biz_id: userCredentials.Business_ID, case_type_id: caseType, status_id: caseStatus })
    getDailyAlerts({ biz_id: userCredentials.Business_ID, case_type_id: caseType, status_id: caseStatus })
    getAllAlerts({ business_id: userCredentials.Business_ID })
  }

  // useEffect
  useEffect(() => {
    updateAccessToFeatures((process.env.HAS_ACCESS_TO_FEATURE === 'true') || false)
    // Fetch alerts Data on Render
    fetchData()
    setActiveModule('alerts')
  }, [caseType, caseStatus])

  useEffect(() => {
    if (rececivedData) {
      setBreakdownSummaryData({ dailyAlertsData, alertsBySeverityData, alertsByStatusData })
      setAllAlertsDataState(allAlertsData)
    }
  }, [dailyAlertsData, alertsBySeverityData, alertsByStatusData, allAlertsData])

  useEffect(() => {
    if (isLoading) { return setShowHeaderLoader(true) }
    if (notLoading) { return setShowHeaderLoader(false) }
  }, [isDailyAlertsLoading, isAlertsBySeverityLoading, isAlertsByStatusLoading])

  useEffect(() => {
    if (isError) showAlert({ type: 'error', message: 'An error occured in fetching the data.' })
  }, [isDailyAlertsError || isAlertsBySeverityError || isAlertsByStatusError])

  return (
    <App>
      <Sidebar />
      <PageWrapper>
        <Header subHeader={<AlertsSubHeader />} />
        <Content>
          <AlertsWrapper>
            { isLoading ?
              <LoadingPage />
            :
              <>
                {!auth.hasAccessToFeatures &&
                  <NoAccess />
                }
                {!!auth.hasAccessToFeatures &&
                  <Container>
                    <AlertsBreakdownWrapper>
                      <AlertsBreakdownColWrapper>
                        <AlertsBreakdownRowWrapper>
                          <AlertBreakDownBoxes data={alertsByStatusData} />
                        </AlertsBreakdownRowWrapper>
                        <SeverityChart data={alertsBySeverityData} />
                      </AlertsBreakdownColWrapper>

                      <AlertsBreakdownColWrapper>
                        <ActivityChart
                          titleIcon={<Image src={WaveIcon} />}
                          data={breakdownSummaryData.dailyAlertsData}
                          title="Daily Alerts Activity"
                        />
                      </AlertsBreakdownColWrapper>

                    </AlertsBreakdownWrapper>
                    <AlertsBreakdownTableWrapper>
                      <AlertsTable data={allAlertsDataState} isLoading={isAllAlertsLoading} />
                    </AlertsBreakdownTableWrapper>
                  </Container>
                }
              </>
            }
          </AlertsWrapper>
        </Content>
      </PageWrapper>
    </App>
  )
}

// Default Props
Alerts.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
Alerts.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    showAlert: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
    updateAccessToFeatures: PropTypes.func
  }),
  auth: PropTypes.shape({
    hasAccessToFeatures: PropTypes.bool,
  }),
  ui: PropTypes.shape({
    isNewCaseCreated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object)
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts)
