/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Library Components
import { Space } from 'antd'

// Assets
import MenuIcon from 'assets/images/table-menu.svg'
import FilterIcon from 'assets/images/filter.svg'
import ArrowDownIcon from 'assets/images/arrow-down.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  TableWrapper,
  TableHeaderWrapper,
  HeaderTitleWrapper,
  HeaderIcon,
  HeaderTitle,
  HeaderFilterButton,
  FilterWrapper,
  TableBodyOverflowWrapper,
  TableBodyWrapper,
  TableBodyHeaderWrapper,
  TableBodyHeaderItem,
  TableBodyHeaderText,
  TableBodySubHeaderText,
  TableBodyHeaderIcon,
  TableBodyChildrenWrapper,
  HeaderOptionsWrapper,
} from './Table.elements'

// Views
import { Image, IconButton, Pagination } from 'views/components'

// Map Redux Props
const mapStateToProps = ({ filters }) => ({ filters })
const mapDispatchToProps = actions
function Table(props) {
  // Destructure
  const {
    title,
    headers,
    withStartIcon,
    withEndIcon,
    gap,
    headerOptions,
    children,
    totalItems,
    minWidth,
    filterComponents,
    onFilterToggle,
    filters,
    actions,
  } = props
  const { caseManagementFilters } = filters
  const { setCaseManagementFilters } = actions

  // States
  const [collapseFilter, setCollapseFilter] = useState(true)
  const { sortDirection, sortBy, pageSize, pageIndex } = caseManagementFilters

  // Variables
  const withHeader = headers.length
  const isHeaderSortActive = ({
    identifier
  }) => identifier === sortBy
  const headerPadding = ((withEndIcon && withStartIcon) ? '20px 50px 20px 60px' : (
    (withEndIcon && !withStartIcon) ? '20px 50px 20px 20px' : (
      (!withEndIcon && withStartIcon) ? '20px 20px 20px 60px' : '20px'
    )
  ))

  // Functions
  const handleCollapseFilter = () => {
    if (window.innerWidth < 768) {
      onFilterToggle()
    } else {
      setCollapseFilter((prevState) => !prevState)
    }
  }

  const handleSort = async (args) => {
    const { identifier } = args
    const sortDirectionToggle = sortDirection === 'DESC' ? 'ASC' : 'DESC'

    if (sortBy !== identifier) {
      await setCaseManagementFilters({ ...caseManagementFilters, sortBy: identifier, sortDirection: 'ASC' })
    } else {
      await setCaseManagementFilters({ ...caseManagementFilters, sortDirection: sortDirectionToggle })
    }
  }

  const handlePageChange = async ({ pageIndex, pageSize }) => {
    await setCaseManagementFilters({ ...caseManagementFilters, pageIndex, pageSize })
  }

  return (
    <>
      <TableWrapper allowZeroExpanded>
        <TableHeaderWrapper>
          <HeaderTitleWrapper>
            <HeaderIcon>
              <Image src={MenuIcon} />
            </HeaderIcon>
            <HeaderTitle> {title} </HeaderTitle>
          </HeaderTitleWrapper>
          <HeaderFilterButton>
            <Space>
              <IconButton onClick={handleCollapseFilter}>
                <Image src={FilterIcon} width={20} height={20} />
              </IconButton>
              <HeaderOptionsWrapper>
                <Space> {headerOptions} </Space>
              </HeaderOptionsWrapper>
            </Space>
          </HeaderFilterButton>
        </TableHeaderWrapper>
        <FilterWrapper collapseFilter={collapseFilter} withFilter={filterComponents}>
          {filterComponents}
        </FilterWrapper>
        <TableBodyOverflowWrapper>
          <TableBodyWrapper minWidth={minWidth}>
            <TableBodyHeaderWrapper gap={gap} headerPadding={headerPadding} showHeader={withHeader}> {
              headers.map(({ name, subHeader, sort, identifier, width }) => (
                <TableBodyHeaderItem
                  key={name}
                  sortable={sort}
                  width={width}
                  onClick={() => sort && handleSort({ identifier })}
                >
                  <TableBodyHeaderText>
                    {name}
                    {subHeader &&
                      <TableBodySubHeaderText> {subHeader} </TableBodySubHeaderText>}
                  </TableBodyHeaderText>
                  {sort && (
                  <TableBodyHeaderIcon
                    sortDirection={sortDirection}
                    active={isHeaderSortActive({ identifier })}
                  >
                    <Image src={ArrowDownIcon} width={18} height={18} />
                  </TableBodyHeaderIcon>
                    )
                  }
                </TableBodyHeaderItem>
              ))
            }
            </TableBodyHeaderWrapper>
            <TableBodyChildrenWrapper>
              {children}
            </TableBodyChildrenWrapper>
          </TableBodyWrapper>
        </TableBodyOverflowWrapper>
      </TableWrapper>
      <Pagination
        itemsCountPerPage={pageSize}
        totalItemsCount={totalItems}
        pageIndex={pageIndex}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
      />
    </>
  )
}

// Default Props
Table.defaultProps = {
  title: '',
  headers: [],
  headerOptions: '',
  children: '',
  minWidth: 0,
  totalItems: 0,
  filterComponents: '',
  onFilterToggle: () => {},
  filters: {},
  actions: {}
}

// Proptypes Validation
Table.propTypes = {
  title: PropTypes.string,
  withStartIcon: PropTypes.bool,
  withEndIcon: PropTypes.bool,
  gap: PropTypes.number,
  headers: PropTypes.instanceOf(Array),
  headerOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  minWidth: PropTypes.number,
  totalItems: PropTypes.number,
  filterComponents: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  onFilterToggle: PropTypes.func,
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.shape({
      sortBy: PropTypes.string,
      sortDirection: PropTypes.string,
      pageIndex: PropTypes.number,
      pageSize: PropTypes.number,
    })
  }),
  actions: PropTypes.shape({
    setCaseManagementFilters: PropTypes.func
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
