import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  AlertDetailsFormWrapper,
  FormGroupWrapperRow,
  FormGroupWrapperHeader,
  FormGroupWrapperSmall,
  FormGroupWrapperLarge,
  FormTitle,
  FormErrorText,
  FormInputGroupItem,
} from './AlertDetailsForm.elements'

// Views
import { Form, FormTextField, FormTextArea, FormSelect, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AlertDetailsForm(props) {
  // Destructure
  const { data } = props
  const { severity, alert_id, last_update, timestamp, rule_code, rule_description, assignee, alert_status } = data

  // Variables
  const initialValues = {
    severity: severity || '',
    alert_id: alert_id || '',
    last_update: last_update || '',
    timestamp: timestamp || '',
    rule_code: rule_code || '',
    rule_description: rule_description || '',
    assignee: assignee || '',
    alert_status: alert_status || '',
    notes: '',
  }
  const validationSchema = Yup.object().shape({})
  const assigneeOptions = [
    { value: 'AnalystLevel1', label: 'Analyst Level 1' },
    { value: 'AnalystLevel2', label: 'Analyst Level 2' },
    { value: 'AnalystLevel2', label: 'Analyst Level 2' },
  ]
  const statusOptions = [
    { value: 'open', label: 'Open' },
    { value: 'pending', label: 'Pending' },
    { value: 'closed', label: 'Closed' },
  ]

  // States
  const [formValues, setFormValues] = useState(initialValues)
  const [errmsg] = useState(null)

  useEffect(() => {
    if (data) setFormValues({ ...data, notes: '' })
  }, [data])

  return (
    <Form
      enableReinitialize
      initialValues={formValues}
      validationSchema={validationSchema}
      autoComplete="off"
    >
      {errmsg &&
        <FormErrorText>{errmsg}</FormErrorText>
      }
      <AlertDetailsFormWrapper>

        <FormGroupWrapperRow>
          <FormTitle>Alert Details</FormTitle>
          <FormGroupWrapperHeader>
            <FormInputGroupItem>
              <FormSelect placeholder="Assignee" type="text" name="assignee" options={assigneeOptions} />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSelect placeholder="Status" type="text" name="status" options={statusOptions} />
            </FormInputGroupItem>
          </FormGroupWrapperHeader>
        </FormGroupWrapperRow>

        <FormGroupWrapperRow>
          <FormGroupWrapperSmall>
            <FormInputGroupItem>
              <FormTextField label="Severity" type="text" name="severity" disabled />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Alert ID" type="text" name="alert_id" disabled />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Last Update" type="text" name="last_update" disabled />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Timestamp" type="text" name="timestamp" disabled />
            </FormInputGroupItem>
          </FormGroupWrapperSmall>

          <FormGroupWrapperLarge>
            <FormInputGroupItem>
              <FormTextField label="Scenarios Name" type="text" name="rule_code" disabled />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextArea label="Summary" type="text" name="rule_description" disabled />
            </FormInputGroupItem>
          </FormGroupWrapperLarge>
        </FormGroupWrapperRow>

        <FormGroupWrapperLarge>
          <FormTitle>Notes</FormTitle>
          <FormTextArea type="text" name="notes" />
        </FormGroupWrapperLarge>

        <FormGroupWrapperRow>
          <FormTitle> </FormTitle>
          <FormGroupWrapperHeader>
            <Button>Save</Button>
            <Button disabled>Delete</Button>
          </FormGroupWrapperHeader>
        </FormGroupWrapperRow>
      </AlertDetailsFormWrapper>
    </Form>
  )
}

// Default Props
AlertDetailsForm.defaultProps = {
  formRef: {},
  form: {},
}

// Proptypes Validation
AlertDetailsForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({ addressOrTransactionIdToAssign: PropTypes.instanceOf(Object) }),
  data: PropTypes.shape({
    severity: PropTypes.string,
    alert_id: PropTypes.string,
    last_update: PropTypes.string,
    timestamp: PropTypes.string,
    rule_description: PropTypes.string,
    rule_code: PropTypes.string,
    assignee: PropTypes.string,
    alert_status: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertDetailsForm)
