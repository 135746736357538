import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import LinkIcon from 'assets/images/icon-link.svg'

// Store
import { actions } from 'core/store'

// Styled Components
import {
  PageWrapper,
  PoliciesWrapper,
  RowContainer,
  RowWrapper,
  Title,
  PolicyItem,
  ItemDataContainer,
  ItemTitle,
  ItemData,
  ItemLink,
} from './Policies.elements'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Views
import { Container, Button, Image, Form, FormTextField, FormTextArea } from 'views/components'
import {
  App,
  Header,
  Sidebar,
  Content,
  AlertsSubHeader,
  LoadingPage,
  NoAccess
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function Policies(props) {
  // Props
  const { auth, actions } = props
  // Store Actions
  const { updateAccessToFeatures } = actions

  // Variables
  const initialValues = {
    policyTitle: '',
    policyDescription: '',
  }
  const validationSchema = Yup.object().shape({})

  // Hooks
  const [policiesList, setPoliciesList] = useLocalStorage('policies', [])

  // States
  const [formValues] = useState(initialValues)
  const [isCreatePolicyActive, setIsCreatePolicyActive] = useState(false)
  const [isLoading] = useState(false)

  // Functions
  const handleOnSubmit = (values) => {
    if (!values.policyTitle || !values.policyDescription) return setIsCreatePolicyActive(false)
    const policyItem = {
      policy_id: uuid(),
      policy_info: {
        ...values,
        status: 'inactive',
      },
      scenarios: []
    }
    setPoliciesList([policyItem, ...policiesList])
    setIsCreatePolicyActive(false)
  }

  // UseEffect
  useEffect(() => {
    updateAccessToFeatures((process.env.HAS_ACCESS_TO_FEATURE === 'true') || false)
  }, [])
  return (
    <App>
      <Sidebar />
      <PageWrapper>
        <Header subHeader={<AlertsSubHeader />} />
        { isLoading ?
          <LoadingPage />
        :
          <>
            {!!auth.hasAccessToFeatures &&
              <Content>
                <PoliciesWrapper>

                  <RowWrapper>
                    <RowContainer>
                      <Title>Available Policies</Title>
                      <Button onClick={() => setIsCreatePolicyActive(true)}>Add Policy</Button>
                    </RowContainer>
                  </RowWrapper>

                  <Container>
                    {isCreatePolicyActive &&
                      <PolicyItem>
                        <Form
                          enableReinitialize
                          initialValues={formValues}
                          validationSchema={validationSchema}
                          onSubmit={handleOnSubmit}
                          autoComplete="off"
                        >
                          <FormTextField label="Policy Title" type="text" name="policyTitle" required />
                          <FormTextArea label="Policy Description" name="policyDescription" rows={4} required />
                          <ItemDataContainer>
                            <Button type="submit">Save</Button>
                            <Button color="secondary" onClick={() => setIsCreatePolicyActive(false)}>Cancel</Button>
                          </ItemDataContainer>
                        </Form>
                      </PolicyItem>
                    }
                    {policiesList.map(({ policy_info: { policyTitle, policyDescription, status }, policy_id }) =>
                      <PolicyItem key={policy_id}>
                        <ItemDataContainer>
                          <ItemTitle>{policyTitle}</ItemTitle>
                          <ItemData>{policyDescription}</ItemData>
                        </ItemDataContainer>
                        <ItemDataContainer>
                          <ItemTitle color={status === 'active' ? 'green' : 'red'}>{status}</ItemTitle>
                          <NavLink to={`/alerts/policies/${policy_id}`}>
                            <ItemLink>
                              View
                              <Image height={18} width={18} src={LinkIcon} />
                            </ItemLink>
                          </NavLink>
                        </ItemDataContainer>
                      </PolicyItem>
                    )}
                  </Container>
                </PoliciesWrapper>
              </Content>
            }
            {!auth.hasAccessToFeatures &&
              <NoAccess />
            }
          </>
        }
      </PageWrapper>
    </App>
  )
}

// Default Props
Policies.defaultProps = {
}

// Proptypes Validation
Policies.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    updateAccessToFeatures: PropTypes.func
  }),
  auth: PropTypes.shape({
    hasAccessToFeatures: PropTypes.bool,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Policies)
