import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Assets
import KeyIcon from 'assets/images/icon-2.svg'
import WaveIcon from 'assets/images/icon-1.svg'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

import {
  useFetchWhitelistingPagedTable,
  useFetchWhitelistingRiskBucket,
  useFetchWhitelistingAddressHistory,
} from 'core/hooks/api'

// Styled Elements
import {
  WhiteListingWrapper,
  WhiteListingTableWrapper,
  WhiteListingChartWrapper,
  RiskScoreChartWrapper,
  ActivityChartWrapper,
} from './Whitelisting.elements'

// Views
import { Container, Image } from 'views/components'

import { App, Header, Sidebar, Content, SearchBar, RiskScoreChart, ActivityChart, AddressesTable } from 'views/layouts'

// Map Redux Props
const mapStateToProps = ({ filters, ui }) => ({ filters, ui })
const mapDispatchToProps = actions

function WhiteListing(props) {
  // Destructure
  const { actions, filters, ui } = props
  const { whitelistingFilters } = filters
  const { isPageTableUpdated } = ui

  // Store Actions
  const { setShowHeaderLoader, setIsPageTableUpdated, showAlert } = actions

  // Hooks
  const [whitelistingData, setWhitelistingData] = useState({})
  const [whitelistingFiltersState, setWhitelistingFiltersState] = useState({})
  const [userCredentials] = useLocalStorage('userCredentials', false)
  const {
    whitelistingPagedTableData,
    getWhitelistingPagedTable,
    isWhitelistingPagedTableLoading,
    isWhitelistingPagedTableError
  } = useFetchWhitelistingPagedTable()
  const {
    whitelistingRiskBucketData,
    getWhitelistingRiskBucket,
    isWhitelistingRiskBucketLoading,
    isWhitelistingRiskBucketError
  } = useFetchWhitelistingRiskBucket()
  const {
    whitelistingAddressHistoryData,
    getWhitelistingAddressHistory,
    isWhitelistingAddressHistoryLoading,
    isWhitelistingAddressHistoryError
  } = useFetchWhitelistingAddressHistory()

  // Variables
  const isLoading = (
    isWhitelistingPagedTableLoading || isWhitelistingRiskBucketLoading || isWhitelistingAddressHistoryLoading
  )
  const notLoading = (
    !isWhitelistingPagedTableLoading && !isWhitelistingRiskBucketLoading && !isWhitelistingAddressHistoryLoading
  )
  const isError = (isWhitelistingPagedTableError || isWhitelistingRiskBucketError || isWhitelistingAddressHistoryError)

  // Functions
  const fetchData = (params) => {
    const { end, pageIndex, pageSize, sortBy, sortDirection, start } = params

    // only fetching the chart data when dates change
    if (!!end && !!start) {
      getWhitelistingRiskBucket({
        biz_id: userCredentials.Business_ID,
        start_range: start,
        end_range: end,
      })
      getWhitelistingAddressHistory({
        biz_id: userCredentials.Business_ID,
        start_range: start,
        end_range: end,
      })
    }

    getWhitelistingPagedTable({
      biz_id: userCredentials.Business_ID,
      end_range: whitelistingFilters.end,
      start_range: whitelistingFilters.start,
      sortby: sortBy,
      sortdir: sortDirection,
      pageindex: pageIndex,
      pagesize: pageSize,
    })
  }

  // useEffects
  useEffect(() => {
    const { end, pageIndex, pageSize, sortBy, sortDirection, start } = whitelistingFilters
    if (start === whitelistingFiltersState?.start && end === whitelistingFiltersState?.end) {
      fetchData({ pageIndex, pageSize, sortBy, sortDirection })
      return setWhitelistingFiltersState(whitelistingFilters)
    }
    fetchData(whitelistingFilters)
    return setWhitelistingFiltersState(whitelistingFilters)
  }, [whitelistingFilters])

  // useEffect
  useEffect(() => {
    if (isLoading) { return setShowHeaderLoader(true) }
    if (notLoading) {
      if (whitelistingPagedTableData) { setWhitelistingData(whitelistingPagedTableData) }
      setShowHeaderLoader(false)
    }
  }, [isWhitelistingPagedTableLoading, isWhitelistingRiskBucketLoading, isWhitelistingAddressHistoryLoading])

  // checking for updates on table data
  useEffect(() => {
    if (isPageTableUpdated) { setIsPageTableUpdated(false); fetchData(whitelistingFilters) }
  }, [isPageTableUpdated])

  useEffect(() => {
    if (isError) showAlert({ type: 'error', message: 'An error occured in fetching the data.' })
  }, [isWhitelistingPagedTableError, isWhitelistingRiskBucketError, isWhitelistingAddressHistoryError])

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          <WhiteListingWrapper>
            <SearchBar user_id={userCredentials.User_ID} type="address" />
            <Container>
              <WhiteListingChartWrapper>
                <RiskScoreChartWrapper>
                  <RiskScoreChart
                    titleIcon={<Image src={KeyIcon} />}
                    data={whitelistingRiskBucketData && whitelistingRiskBucketData.chart}
                    summaryValue={whitelistingRiskBucketData && whitelistingRiskBucketData.totalValue}
                    summaryText="Total"
                    title="Address Distribution by Risk Score"
                  />
                </RiskScoreChartWrapper>
                <ActivityChartWrapper>
                  <ActivityChart
                    titleIcon={<Image src={WaveIcon} />}
                    data={whitelistingAddressHistoryData && whitelistingAddressHistoryData}
                    title="Daily Whitelisting Activity"
                  />
                </ActivityChartWrapper>
              </WhiteListingChartWrapper>
            </Container>
            <Container>
              <WhiteListingTableWrapper>
                <AddressesTable
                  data={whitelistingData}
                  onChange={fetchData}
                  isLoading={isWhitelistingPagedTableLoading}
                />
              </WhiteListingTableWrapper>
            </Container>
          </WhiteListingWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
WhiteListing.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
WhiteListing.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  filters: PropTypes.shape({
    whitelistingFilters: PropTypes.instanceOf(Object)
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(WhiteListing)
