import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Utils
import blockChainName from 'common/utils/blockChainName'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import {
  useFetchDashboardTotalCases,
  useFetchDashboardCaseTransactions,
  useFetchDashboardCaseAddresses,
} from 'core/hooks/api'

// Styled Components
import { CasesWrapper, CaseBreakdownWrapper, CaseBreakdownTableWrapper, CaseBreakdownItem } from './Cases.elements'

// Views
import { Container, DataBreakdownSummary } from 'views/components'
import { App, Header, Sidebar, Content, CaseManagementTable } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function Cases(props) {
  // Destructure
  const { actions, ui, filters } = props

  // Store State
  const { isNewCaseCreated } = ui
  const { caseManagementFilters } = filters
  const { caseType, caseStatus } = caseManagementFilters

  // Store Actions
  const { setActiveModule, setShowHeaderLoader, setNewCaseCreated } = actions

  // States
  const [breakdownSummaryData, setBreakdownSummaryData] = useState()

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const {
    dashboardTotalCasesData, isDashboardTotalCasesLoading, getDashboardTotalCases
  } = useFetchDashboardTotalCases()
  const {
    dashboardCaseTransactionsData, isDashboardCaseTransactionsLoading, getDashboardCaseTransactions
  } = useFetchDashboardCaseTransactions({})
  const {
    dashboardCaseAddressesData, isDashboardCaseAddressesLoading, getDashboardCaseAddresses
  } = useFetchDashboardCaseAddresses({})

  // Variables
  const isLoading = (isDashboardTotalCasesLoading || isDashboardCaseTransactionsLoading || isDashboardCaseAddressesLoading)
  const notLoading = (
    !isDashboardTotalCasesLoading && !isDashboardCaseTransactionsLoading && !isDashboardCaseAddressesLoading
  )
  const rececivedData = (!!dashboardTotalCasesData && !!dashboardCaseTransactionsData && !!dashboardCaseAddressesData)

  // Functions
  const fetchData = () => {
    getDashboardTotalCases({ biz_id: userCredentials.Business_ID, case_type_id: caseType, status_id: caseStatus })
    getDashboardCaseTransactions({ biz_id: userCredentials.Business_ID, case_type_id: caseType, status_id: caseStatus })
    getDashboardCaseAddresses({ biz_id: userCredentials.Business_ID, case_type_id: caseType, status_id: caseStatus })
  }
  const formatAndSetBreakdownData = (breakdownSummaryData) => {
    const result = []
    breakdownSummaryData.map((breakdownData) => {
      const newbreakdownData = []
      breakdownData.breakdownData.map((item) => {
        if (item.name !== 'ERC-20') {
          newbreakdownData.push({
            ...item,
            name: blockChainName(item.name) ? blockChainName(item.name) : item.name
          })
        }
      })
      const newbreakdownSummary = { ...breakdownData, breakdownData: newbreakdownData }
      result.push(newbreakdownSummary)
    })
    setBreakdownSummaryData(() => result)
  }

  // useEffect
  useEffect(() => {
    // Fetch Cases Data on Render
    fetchData()
    setActiveModule('cases')
  }, [caseType, caseStatus])

  useEffect(() => {
    if (rececivedData) {
      formatAndSetBreakdownData([dashboardTotalCasesData, dashboardCaseTransactionsData, dashboardCaseAddressesData])
    }
  }, [dashboardTotalCasesData, dashboardCaseTransactionsData, dashboardCaseAddressesData])

  useEffect(() => {
    if (isLoading) { return setShowHeaderLoader(true) }
    if (notLoading) { return setShowHeaderLoader(false) }
  }, [isDashboardTotalCasesLoading, isDashboardCaseTransactionsLoading, isDashboardCaseAddressesLoading])
  // updating cases when creating a new case
  useEffect(() => {
    if (isNewCaseCreated) {
      getDashboardTotalCases({ biz_id: userCredentials.Business_ID, case_type_id: caseType, status_id: caseStatus })
      return setNewCaseCreated(false)
    }
  }, [isNewCaseCreated])

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          <CasesWrapper>
            <Container>
              <CaseBreakdownWrapper>
                {breakdownSummaryData &&
                  breakdownSummaryData.map(
                    ({ title, figureTotalValue, figureTitle, figureSubtitle, breakdownData }) => (
                      <CaseBreakdownItem key={title}>
                        <DataBreakdownSummary
                          title={title}
                          figureTotalValue={figureTotalValue}
                          figureTitle={figureTitle}
                          figureSubtitle={figureSubtitle}
                          breakdownData={breakdownData}
                        />
                      </CaseBreakdownItem>
                    )
                  )}
              </CaseBreakdownWrapper>
              <CaseBreakdownTableWrapper>
                <CaseManagementTable />
              </CaseBreakdownTableWrapper>
            </Container>
          </CasesWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
Cases.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
Cases.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isNewCaseCreated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object)
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(Cases)
