import React from 'react'
import { NavLink } from 'react-router-dom'

// Assets
import Logo from 'assets/images/brand-logo-blue.png'

// Styled Elements
import {
  ResetPasswordWrapper,
  ResetPasswordInnerWrapper,
  ResetPasswordHeaderWrapper,
  ResetPasswordLogoLink,
  ResetPasswordContentWrapper,
  ResetPasswordFormWrapper,
  ResetPasswordFormInnerWrapper,
  ResetPasswordFormContent,
  ResetPasswordFormTitle,
  ResetPasswordFormDescription,
} from './ResetPassword.elements'

// Components
import { ContainerFluid, Image } from 'views/components'
import { ResetPasswordForm } from 'views/layouts'

function ResetPassword() {
  return (
    <ResetPasswordWrapper>
      <ContainerFluid>
        <ResetPasswordInnerWrapper>
          <ResetPasswordContentWrapper>
            <ResetPasswordHeaderWrapper>
              <NavLink to="/">
                <ResetPasswordLogoLink>
                  <Image src={Logo} width={183} />
                </ResetPasswordLogoLink>
              </NavLink>
            </ResetPasswordHeaderWrapper>
          </ResetPasswordContentWrapper>
          <ResetPasswordFormWrapper>
            <ResetPasswordFormInnerWrapper>
              <ResetPasswordFormContent>
                <ResetPasswordFormTitle>Reset Password</ResetPasswordFormTitle>
                <ResetPasswordFormDescription>
                  Password must have a capital letter, a number, AND a special character
                </ResetPasswordFormDescription>
                <ResetPasswordForm />
              </ResetPasswordFormContent>
            </ResetPasswordFormInnerWrapper>
          </ResetPasswordFormWrapper>
        </ResetPasswordInnerWrapper>
      </ContainerFluid>
    </ResetPasswordWrapper>
  )
}

// Default Props
ResetPassword.defaultProps = {}

// Proptypes Validation
ResetPassword.propTypes = {}

export default ResetPassword
