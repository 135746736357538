import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { riskOptions, typeOptions, statusOptions } from 'common/constants/policyScenarioOptions'

// Store
import { actions } from 'core/store'

// Styled Components
import {
  PageWrapper,
  PoliciesWrapper,
  RowWrapper,
  RowContainer,
  Title,
  PolicyItem,
  ItemDataContainer,
  ItemTitle,
  ItemData,
} from './PolicyDetails.elements'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Views
import { Container, Button, Form, FormTextField, FormSelect, FormTextArea } from 'views/components'
import {
  App,
  Header,
  Sidebar,
  Content,
  AlertsSubHeader,
  ScenariosTable,
  ScenarioBuilder,
  LoadingPage,
  NoAccess
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function PolicyDetails(props) {
  // Props
  const { auth } = props
  // Store Actions
  const { updateAccessToFeatures } = actions

  // Variables
  const scenarioInitialValues = {
    scenarioName: '',
    severity: '',
    type: '',
    status: '',
  }
  const policyInitialValues = {
    policyTitle: '',
    policyDescription: '',
    status: '',
  }
  const validationSchema = Yup.object().shape({})
  const formRef = useRef({})

  // Hooks
  const { policyId } = useParams()
  const [policiesList, setPoliciesList] = useLocalStorage('policies', [])

  // States
  const [isEditPolicyActive, setIsEditPolicyActive] = useState(false)
  const [isCreateScenarioActive, setIsCreateScenarioActive] = useState(false)
  const [scenarioFormValues] = useState(scenarioInitialValues)
  const [policyFormValues, setPolicyFormValues] = useState(policyInitialValues)
  const [currentPolicy, setCurrentPolicy] = useState({})
  const [scenarios, setScenarios] = useState([])
  const [queryData, setQueryData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false)

  // Functions
  const handleOnPolicyUpdate = (values) => {
    const policy_info = {
      ...values,
    }
    const newCurrentPolicy = {
      policy_id: policyId,
      policy_info,
      scenarios: currentPolicy.scenarios
    }

    const newPoliciesList = policiesList.map((item) => {
      if (item.policy_id === policyId) {
        return { ...item, policy_info }
      }
      return item
    })

    setPoliciesList(newPoliciesList)
    setIsEditPolicyActive(false)
    setCurrentPolicy(newCurrentPolicy)
  }

  const handleOnScenarioSubmit = () => {
    const { values } = formRef.current
    const scenarioItem = {
      scenario_id: uuid(),
      scenario_info: {
        ...values,
        status: 'active'
      },
      queryData,
    }
    const newCurrentPolicy = { ...currentPolicy, scenarios: [scenarioItem, ...scenarios] }

    const newPoliciesList = policiesList.map((item) => {
      if (item.policy_id === policyId) {
        return { ...newCurrentPolicy }
      }
      return item
    })

    setScenarios(newCurrentPolicy.scenarios)
    setCurrentPolicy(newCurrentPolicy)
    setPoliciesList(newPoliciesList)
    setIsCreateScenarioActive(false)
    setIsLoading(true)
    setIsSaved(true)
  }

  const handleCancel = () => {
    setIsCreateScenarioActive(false)
    setIsCancelled(true)
  }

  useEffect(() => {
    updateAccessToFeatures((process.env.HAS_ACCESS_TO_FEATURE === 'true') || false)
    const currentPolicy = policiesList.find((item) => item.policy_id === policyId)
    if (currentPolicy) {
      const { policy_info } = currentPolicy
      setPolicyFormValues({
        policyTitle: policy_info.policyTitle,
        policyDescription: policy_info.policyDescription,
        status: policy_info.status,
      })
      setCurrentPolicy(currentPolicy)
      setScenarios(currentPolicy.scenarios)
    }
  }, [])

  useEffect(() => {
    if (isLoading) return setTimeout(() => { setIsLoading(false) }, 200)
  }, [isLoading])
  return (
    <App>
      <Sidebar />
      <PageWrapper>
        <Header subHeader={<AlertsSubHeader />} />
        {isLoading ?
          <LoadingPage />
        :
          <>
            {!auth.hasAccessToFeatures &&
              <NoAccess />
            }
            {!!auth.hasAccessToFeatures &&
              <Content>
                <PoliciesWrapper>
                  <Container>
                    {isEditPolicyActive ?
                      <PolicyItem>
                        <Form
                          enableReinitialize
                          initialValues={policyFormValues}
                          validationSchema={validationSchema}
                          onSubmit={handleOnPolicyUpdate}
                          autoComplete="off"
                        >
                          <RowContainer>
                            <ItemDataContainer>
                              <FormTextField label="Policy Title" type="text" name="policyTitle" required />
                              <FormSelect label="Status" name="status" options={statusOptions} required />
                            </ItemDataContainer>
                            <FormTextArea label="Policy Description" name="policyDescription" rows={4} required />
                            <ItemDataContainer>
                              <Button type="submit">Save</Button>
                              <Button color="secondary" onClick={() => setIsEditPolicyActive(false)}>Cancel</Button>
                            </ItemDataContainer>
                          </RowContainer>
                        </Form>
                      </PolicyItem>
                      :
                      <RowWrapper>
                        <ItemDataContainer>
                          <Title>{currentPolicy?.policy_info?.policyTitle || ''}</Title>
                          <ItemData>{currentPolicy?.policy_info?.policyDescription || ''}</ItemData>
                        </ItemDataContainer>
                        <ItemDataContainer>
                          <ItemTitle
                            color={currentPolicy?.policy_info?.status === 'active' ? 'green' : 'red'}
                          >
                            {currentPolicy?.policy_info?.status}
                          </ItemTitle>
                          <RowContainer>
                            <Button
                              color="default"
                              onClick={() => { setIsEditPolicyActive(true); setIsCreateScenarioActive(false) }}
                            >
                              Edit Policy
                            </Button>
                            <Button
                              onClick={() => { setIsEditPolicyActive(false); setIsCreateScenarioActive(true) }}
                            >
                              Add Scenario
                            </Button>
                          </RowContainer>
                        </ItemDataContainer>
                      </RowWrapper>
                    }

                    {isCreateScenarioActive &&
                      <PolicyItem>
                        <Form
                          enableReinitialize
                          formRef={formRef}
                          initialValues={scenarioFormValues}
                          validationSchema={validationSchema}
                          autoComplete="off"
                        >
                          <RowContainer>
                            <FormTextField label="Scenario Name" type="text" name="scenarioName" required />
                            <FormSelect label="Severity" name="severity" options={riskOptions} required />
                            <FormSelect label="Type" name="type" options={typeOptions} required />
                            <FormSelect label="Status" name="status" options={statusOptions} required />
                            <ItemDataContainer>
                              <Button onClick={handleOnScenarioSubmit}>Save</Button>
                              <Button color="secondary" onClick={handleCancel}>
                                Cancel
                              </Button>
                            </ItemDataContainer>
                          </RowContainer>
                        </Form>
                        <ScenarioBuilder
                          readOnly={false}
                          setQueryData={setQueryData}
                          isCancelled={isCancelled}
                          setIsCancelled={setIsCancelled}
                          isSaved={isSaved}
                          setIsSaved={setIsSaved}
                        />
                      </PolicyItem>
                    }

                    <ScenariosTable
                      isLoading={isLoading}
                      currentPolicy={currentPolicy}
                      setCurrentPolicy={setCurrentPolicy}
                      scenarios={scenarios}
                      setScenarios={setScenarios}
                    />
                  </Container>
                </PoliciesWrapper>
              </Content>
            }
          </>
        }
      </PageWrapper>
    </App>
  )
}

// Default Props
PolicyDetails.defaultProps = {
}

// Proptypes Validation
PolicyDetails.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
  }),
  auth: PropTypes.shape({
    hasAccessToFeatures: PropTypes.bool,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(PolicyDetails)
