import React from 'react'
import PropTypes from 'prop-types'

// Library Components
import { Layout } from 'antd'

// Styled Elements
import { TravelRuleWrapper, TravelRuleTitle } from './TravelRule.elements'

import { App, Header, Sidebar, Content } from 'views/layouts'
import { NoAccess } from '../../layouts'

function TravelRule(props) {
  // Destructure
  const { } = props

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          <NoAccess />
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
TravelRule.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
TravelRule.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  filters: PropTypes.shape({
    whitelistingFilters: PropTypes.instanceOf(Object)
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default TravelRule
