import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import TableMenuIcon from 'assets/images/table-menu.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { AddressesTableWrapper } from './AddressesTable.elements'

// Views
import {
  Image,
  AccordionTable,
  DateRangePicker,
  EmptyTablePlaceholder,
  LoadingTablePlaceholder,
} from 'views/components'

import { AddressesTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = ({ filters }) => ({ filters })
const mapDispatchToProps = actions

function AddressesTable(props) {
  // Destructure
  const { data, headerOptions, actions, isLoading, filters } = props
  const { whitelistingFilters } = filters
  const { setWhitelistingFilters } = actions

  // Variables
  const tableHeaders = [
    { name: 'Address', sort: true, identifier: 'Address', width: 250 },
    { name: 'Chain', sort: true, identifier: 'Chain' },
    { name: 'Balance', sort: true, identifier: 'Balance' },
    { name: 'Risk Score', sort: true, identifier: 'Address_Risk_Score' },
    { name: 'Timestamp', sort: true, identifier: 'CreateTimestamp' },
  ]

  // Functions
  const handleOnTableFilterChange = async (args) => {
    // making sure table goes back to first page
    const changedData = { ...args, pageIndex: 0 }
    await setWhitelistingFilters({ ...whitelistingFilters, ...changedData })
  }

  return (
    <AddressesTableWrapper>
      <AccordionTable
        title="Addresses Table"
        tableFilters={whitelistingFilters}
        setTableFilters={setWhitelistingFilters}
        titleIcon={<Image src={TableMenuIcon} width={30} height={30} />}
        headers={tableHeaders}
        minWidth={700}
        totalItems={data.total && data.total[0]}
        filterComponents={
          <DateRangePicker
            start={whitelistingFilters?.start}
            end={whitelistingFilters?.end}
            onApply={handleOnTableFilterChange}
          />
        }
        headerOptions={headerOptions}
      >
        {data?.items?.length > 0 && !isLoading ? (
          data.items.map(
            ({
              Address_ID,
              Address,
              Asset,
              Balance_USD,
              Address_Risk_Score,
              CreateTimestamp,
              Latest_Activity,
              Flags,
              Balance,
              Created_By,
              Case_ID,
              Chain,
              Received,
              Received_USD,
              Spent,
              Spent_USD,
              Notes,
              Token
            }) => (
              <AddressesTableItem
                key={Address_ID}
                id={Address_ID}
                address={Address}
                asset={Asset}
                balanceUsd={Balance_USD}
                balance={Balance}
                riskScore={Address_Risk_Score}
                flags={Flags}
                createdBy={Created_By}
                latestActivity={Latest_Activity}
                timestamp={CreateTimestamp}
                incoming={Received}
                incomingUsd={Received_USD}
                outgoing={Spent}
                outgoingUsd={Spent_USD}
                caseId={Case_ID}
                chain={Chain}
                notes={Notes}
                token={Token}
              />
            )
          )
        ) : (
          <>
            {isLoading ? (
              <LoadingTablePlaceholder />
            ) : (
              <>{data?.items?.length < 1 ? <EmptyTablePlaceholder /> : <></>}</>
            )}
          </>
        )}
      </AccordionTable>
    </AddressesTableWrapper>
  )
}

// Default Props
AddressesTable.defaultProps = {
  actions: {},
  data: {},
  headerOptions: '',
  ui: {},
  isLoading: false,
  filters: {},
}

// Proptypes Validation
AddressesTable.propTypes = {
  actions: PropTypes.shape({
    setIsPageTableUpdated: PropTypes.func,
    setWhitelistingFilters: PropTypes.func,
  }),
  data: PropTypes.shape({
    items: PropTypes.instanceOf(Array),
    total: PropTypes.instanceOf(Array),
    biz_total: PropTypes.instanceOf(Array),
  }),
  headerOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  isLoading: PropTypes.bool,
  filters: PropTypes.shape({
    whitelistingFilters: PropTypes.instanceOf(Object),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressesTable)
