import React from 'react'

import { NoAccessWrapper, NoAccessTitle } from './NoAccess.elements'

function NoAccess() {
	return (
  <NoAccessWrapper>
    <NoAccessTitle>
      You currently do not have access to this feature
    </NoAccessTitle>
  </NoAccessWrapper>
	)
}

export default NoAccess
