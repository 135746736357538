import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import PlusWhiteIcon from 'assets/images/plus-white.svg'
import UsersIcon from 'assets/images/users.svg'
import BriefCaseIcon from 'assets/images/briefcase.svg'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useFetchAllCases, useFetchCaseCustomer } from 'core/hooks/api'

// Styled Elements
import { CaseManagementTableWrapper, DropdownItemInnerText } from './CaseManagementTable.elements'

// Views
import {
  Table,
  Button,
  Image,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  EmptyTablePlaceholder,
  LoadingTablePlaceholder
} from 'views/components'

import { CaseManagementTableItem, CaseManagementTableFilterForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function CaseManagementTable(props) {
  // Destructure
  const { filters, ui, actions } = props

  // Store States
  const { caseManagementFilters } = filters
  const { isPageTableUpdated } = ui

  // Store Actions
  const {
    toggleCasesManagementFilterModal,
    openCreateIndividualCaseDrawer,
    openCreateEntityCaseDrawer,
    toggleChooseKYCUploadModal,
    setShowHeaderLoader,
    setIsPageTableUpdated
  } = actions

  // Variables
  const tableHeaders = [
    { name: 'Case Type', width: 130 },
    { name: 'Customer ID', width: 120 },
    { name: 'Status', width: 120 },
    { name: 'Transaction Monitoring', subHeader: '(Last 30 days)' },
    { name: 'Whitelisting Activity', subHeader: '(Last 30 days)' },
  ]
  const { sortBy, sortDirection, pageIndex, pageSize, caseType, caseStatus } = caseManagementFilters

  // States
  const [casesData, setCasesData] = useState({ items: [], total: [0] })

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials', false)
  const { allCasesData, getAllCases, isAllCasesLoading } = useFetchAllCases()
  const { caseCustomerData, getCaseCustomer, isCaseCustomerLoading } = useFetchCaseCustomer()

  // Functions
  const fetchCases = () => {
    getAllCases({
      biz_id: userCredentials.Business_ID,
      pagesize: pageSize,
      pageindex: pageIndex,
      sortcol: sortBy,
      sortdir: sortDirection,
      case_type_id: caseType,
      status_id: caseStatus,
    })
  }
  const handleOnTableFilterToggle = () => {
    if (window.innerWidth < 920) {
      toggleCasesManagementFilterModal()
    }
  }

  const handleOnTableSearchFilterChange = (customerId) => {
    if (customerId) {
      getCaseCustomer({
        customer_id: customerId,
      })
    } else {
      fetchCases()
    }
  }

  // UseEffects
  useEffect(() => {
    if (allCasesData) {
      setCasesData(allCasesData)
    }
  }, [allCasesData])

  useEffect(() => {
    if (caseCustomerData) {
      setCasesData(() => ({
        ...allCasesData,
        items: [caseCustomerData]
        })
      )
    }
  }, [caseCustomerData])

  useEffect(() => {
    if (isCaseCustomerLoading || isAllCasesLoading) {
      setShowHeaderLoader(true)
    } else if (!isCaseCustomerLoading && !isAllCasesLoading) {
      setShowHeaderLoader(false)
    }
  }, [isCaseCustomerLoading, isAllCasesLoading])

  useEffect(() => {
    if (isPageTableUpdated || caseManagementFilters) {
      // Fetch Cases Data on page update
      fetchCases()
      if (isPageTableUpdated) return setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated, caseManagementFilters])

  return (
    <CaseManagementTableWrapper>
      <Table
        title="Case Table"
        headers={tableHeaders}
        defaultSortOptions={{
          sortBy: 'AddressCount',
          sortDirection: 'DESC',
        }}
        withStartIcon
        withEndIcon
        totalItems={casesData.total[0]}
        headerOptions={
          <>
            <Button variant="outlined" onClick={() => { toggleChooseKYCUploadModal() }}>
              Import
            </Button>
            <UncontrolledDropdown direction="down">
              <DropdownToggle>
                <Button startIcon={<Image width={16} height={16} src={PlusWhiteIcon} />}>Create Case</Button>
              </DropdownToggle>
              <DropdownMenu left>
                <DropdownItem onClick={() => { openCreateIndividualCaseDrawer() }}>
                  <Image src={UsersIcon} width={16} height={16} />
                  <DropdownItemInnerText>Individual Case</DropdownItemInnerText>
                </DropdownItem>
                <DropdownItem onClick={() => { openCreateEntityCaseDrawer() }}>
                  <Image src={BriefCaseIcon} width={16} height={16} />
                  <DropdownItemInnerText>Entity Case</DropdownItemInnerText>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        }
        minWidth={900}
        filterComponents={
          <CaseManagementTableFilterForm
            onCustomerSearch={handleOnTableSearchFilterChange}
          />
        }
        onFilterToggle={handleOnTableFilterToggle}
      >
        {(casesData.items.length > 0 && !(isAllCasesLoading || isCaseCustomerLoading)) ? (
          casesData.items.map(({ Case_ID, Case_Type, Case_Status, Customer_ID, TxCount, AddressCount }) => (
            <CaseManagementTableItem
              key={Case_ID}
              caseId={Case_ID}
              caseType={Case_Type}
              caseStatus={Case_Status}
              customerId={Customer_ID}
              transactionCount={TxCount}
              addressCount={AddressCount}
            />
          ))
        ) : (
          <>
            {(isAllCasesLoading || isCaseCustomerLoading) ?
              <LoadingTablePlaceholder />
            :
              <>
                {casesData.items.length < 1 ?
                  <EmptyTablePlaceholder />
                :
                  <></>
                }
              </>
            }
          </>
        )}
      </Table>
    </CaseManagementTableWrapper>
  )
}

// Default Props
CaseManagementTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
CaseManagementTable.propTypes = {
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    toggleCasesManagementFilterModal: PropTypes.func,
    openCreateIndividualCaseDrawer: PropTypes.func,
    openCreateEntityCaseDrawer: PropTypes.func,
    toggleChooseKYCUploadModal: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagementTable)
