import React from 'react'

// Assets
import NoTableResultIcon from 'assets/images/icon-no-result.svg'

// Styled Elements
import {
  EmptyTablePlaceholderWrapper,
  EmptyTablePlaceholderImage,
  EmptyTablePlaceholderTextWrapper,
  EmptyTablePlaceholderText,
  EmptyTablePlaceholderSubText,
} from './EmptyTablePlaceholder.elements'

// Views
import { Image } from 'views/components'

function EmptyTablePlaceholder() {
  return (
    <EmptyTablePlaceholderWrapper>
      <EmptyTablePlaceholderImage>
        <Image src={NoTableResultIcon} />
      </EmptyTablePlaceholderImage>
      <EmptyTablePlaceholderTextWrapper>
        <EmptyTablePlaceholderText>No search result</EmptyTablePlaceholderText>
        <EmptyTablePlaceholderSubText>Please, try a different combination of filters</EmptyTablePlaceholderSubText>
      </EmptyTablePlaceholderTextWrapper>
    </EmptyTablePlaceholderWrapper>
  )
}

// Default Props
EmptyTablePlaceholder.defaultProps = {}

// Proptypes Validation
EmptyTablePlaceholder.propTypes = {}

export default EmptyTablePlaceholder
