import React from 'react'
import { useParams } from 'react-router-dom'

// Styled Elements
import {
  CasesSubHeaderWrapper,
  CasesSubHeaderNavList,
  CasesSubHeaderNavListItem,
  CasesSubHeaderNavListLink,
} from './CasesSubHeader.elements'

function CasesSubHeader() {
  // Hooks
  const { customerId } = useParams()

  return (
    <CasesSubHeaderWrapper>
      <CasesSubHeaderNavList>
        <CasesSubHeaderNavListItem>
          <CasesSubHeaderNavListLink exact activeClassName="active" to={`/case-management/details/${customerId}`}>
            Details
          </CasesSubHeaderNavListLink>
        </CasesSubHeaderNavListItem>
        <CasesSubHeaderNavListItem>
          <CasesSubHeaderNavListLink exact activeClassName="active" to={`/case-management/addresses/${customerId}`}>
            Addresses
          </CasesSubHeaderNavListLink>
        </CasesSubHeaderNavListItem>
        <CasesSubHeaderNavListItem>
          <CasesSubHeaderNavListLink exact activeClassName="active" to={`/case-management/transactions/${customerId}`}>
            Transactions
          </CasesSubHeaderNavListLink>
        </CasesSubHeaderNavListItem>
        <CasesSubHeaderNavListItem>
          <CasesSubHeaderNavListLink exact activeClassName="active" to={`/case-management/alerts/${customerId}`}>
            Alerts
          </CasesSubHeaderNavListLink>
        </CasesSubHeaderNavListItem>
      </CasesSubHeaderNavList>
    </CasesSubHeaderWrapper>
  )
}

export default CasesSubHeader
