import React, { useEffect } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useCreateCase, useFetchAllCaseCountries } from 'core/hooks/api'

// Styled Elements
import { CreateEntityCaseFormWrapper, FormTitle, FormInputGroupItem } from './CreateEntityCaseForm.elements'

// Views
import { Form, FormTextField, FormSelect } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function CreateEntityCaseForm(props) {
  // Destructure
  const { formRef, form, actions } = props

  // Store State
  const { countryListOptions } = form

  // Store Actions
  const {
    setCountryListOptions, closeCreateEntityCaseDrawer, setNewCaseCreated, setIsPageTableUpdated, showAlert
  } = actions

  // Variables
  const initialValues = {
    customerId: '',
    companyName: '',
    incorporationCountry: '',
    companyAddress: '',
  }

  const validationSchema = Yup.object().shape({
    customerId: Yup.string().required('Customer Id is required'),
    companyName: Yup.string(),
    incorporationCountry: Yup.string(),
    companyAddress: Yup.string(),
  })

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const { allCaseCountriesData, getAllCaseCountries } = useFetchAllCaseCountries()
  const { createCase, createCaseError, isCreateCaseLoading, isCreateCaseSuccess } = useCreateCase()

  // Functions
  const handleOnSubmit = (values) => {
    const { customerId, companyName, incorporationCountry, companyAddress } = values

    const payload = {
      adverse_media: 0,
      biz_id: userCredentials.Business_ID,
      case_risk: 0,
      case_type: 2,
      company_name: companyName,
      country_address: companyAddress,
      country_incorporation: incorporationCountry,
      customer_id: customerId,
      pep: 0,
      sanction: 0,
      status: 0,
    }
    // removing empty fields on object
    Object.keys(payload).forEach((k) => payload[k] === '' && delete payload[k]);
    createCase(payload)
  }

  // useEffect
  useEffect(() => {
    if (!countryListOptions.length) {
      getAllCaseCountries()
    }
  }, [])

  useEffect(() => {
    if (!countryListOptions.length && allCaseCountriesData) {
      const countriesList = allCaseCountriesData.map(({ Description, Code }) => ({ value: Code, label: Description }))
      setCountryListOptions(countriesList)
    }
  }, [allCaseCountriesData])
  // closing drawer and refreshing data on cases page when api request is successfull
  useEffect(() => {
    if (!isCreateCaseLoading && isCreateCaseSuccess) {
      closeCreateEntityCaseDrawer()
      setNewCaseCreated(true)
      setIsPageTableUpdated(true)
    }
    if (!isCreateCaseLoading && createCaseError) {
      showAlert({
        type: 'error',
        message: 'An error occured in saving the case, Customer ID may have already been used. Please try again.'
      })
    }
  }, [isCreateCaseSuccess, isCreateCaseLoading, createCaseError])

  return (
    <CreateEntityCaseFormWrapper>
      <FormTitle>What is the Customer ID of the case you want to create?</FormTitle>
      <Form
        formRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <FormInputGroupItem>
          <FormTextField placeholder="Customer ID e.g. ID0001" type="text" name="customerId" />
        </FormInputGroupItem>
        <FormInputGroupItem>
          <FormTextField placeholder="Company Name" type="text" name="companyName" />
        </FormInputGroupItem>
        <FormInputGroupItem>
          <FormSelect placeholder="Incorporation Country" name="incorporationCountry" options={countryListOptions} />
        </FormInputGroupItem>
        <FormInputGroupItem>
          <FormSelect placeholder="Company Address" name="companyAddress" options={countryListOptions} />
        </FormInputGroupItem>
      </Form>
    </CreateEntityCaseFormWrapper>
  )
}

// Default Props
CreateEntityCaseForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
}

// Proptypes Validation
CreateEntityCaseForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    countryListOptions: PropTypes.instanceOf(Array),
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    closeCreateEntityCaseDrawer: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEntityCaseForm)
