import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchCaseCustomer, useFetchCaseAlerts } from 'core/hooks/api'

// Styled Components
import { AlertsWrapper, CaseStatusSummaryWrapper } from './Alerts.elements'

// Views
import { Container } from 'views/components'

import { App, Header, Sidebar, Content, CasesSubHeader, CaseStatusSummary, AlertsTable } from 'views/layouts'

// Map Redux Props
const mapStateToProps = ({ filters, ui }) => ({ filters, ui })
const mapDispatchToProps = actions

function Alerts(props) {
  // Destructure
  const { actions, ui } = props
  const { isPageTableUpdated } = ui

  // Store Actions
  const { setActiveModule, setActiveCase, setShowHeaderLoader, setIsPageTableUpdated, showAlert } = actions

  // Hooks
  const { customerId } = useParams()
  const { caseCustomerData, getCaseCustomer, isCaseCustomerLoading, isCaseCustomerError } = useFetchCaseCustomer()
  const { caseAlertsData, getCaseAlerts, isCaseAlertsLoading, isCaseAlertsError } = useFetchCaseAlerts()

  // Variables
  const isLoading = (isCaseAlertsLoading || isCaseCustomerLoading)
  const notLoading = (!isCaseAlertsLoading && !isCaseCustomerLoading)
  const isTableUpdated = (caseCustomerData && (!caseAlertsData || isPageTableUpdated))
  const isError = (isCaseAlertsError || isCaseCustomerError)

  // Functions
  const fetchData = () => {
    getCaseAlerts({ customer_id: customerId })
  }

  // useEffect
  useEffect(() => {
    setActiveModule('addresses')
  }, [])
  // fetching data on initial load, or on page table update, or on filter change
  useEffect(() => {
    if (customerId && !caseCustomerData) { return getCaseCustomer({ customer_id: customerId }) }
    if (isTableUpdated) {
      fetchData()
      setActiveCase(caseCustomerData.Case_ID)
      if (isPageTableUpdated) { getCaseCustomer({ customer_id: customerId }); setIsPageTableUpdated(false) }
    }
  }, [customerId, caseCustomerData, isPageTableUpdated])

  useEffect(() => {
    if (isLoading) { return setShowHeaderLoader(true) }
    if (notLoading) { return setShowHeaderLoader(false) }
  }, [isCaseCustomerLoading, isCaseAlertsLoading])

  useEffect(() => {
    if (isError) showAlert({ type: 'error', message: 'An error occured in fetching the data.' })
  }, [isCaseAlertsError, isCaseCustomerError])

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header subHeader={<CasesSubHeader />} />
        <Content>
          <AlertsWrapper>
            <Container>
              <CaseStatusSummaryWrapper>
                {caseCustomerData && (
                  <CaseStatusSummary
                    customerId={caseCustomerData.Customer_ID}
                    total={caseAlertsData?.length}
                    status={caseCustomerData.Case_Status}
                    type="alerts"
                    caseType={caseCustomerData.Case_Type}
                  />
                )}
              </CaseStatusSummaryWrapper>
            </Container>
            <Container>
              <AlertsTable
                data={caseAlertsData}
                isLoading={isCaseAlertsLoading}
              />
            </Container>
          </AlertsWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
Alerts.defaultProps = {
  actions: {},
  filters: {},
  ui: {}
}

// Proptypes Validation
Alerts.propTypes = {
  actions: PropTypes.shape({
    toggleUploadCaseModal: PropTypes.func,
    setActiveModule: PropTypes.func,
    setActiveCase: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  filters: PropTypes.shape({
    whitelistingFilters: PropTypes.instanceOf(Object)
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts)
