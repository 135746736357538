import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn: true,
  hasAccessToFeatures: false,
}

const store = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateRole: (state) => {
      state.role = 'dev'
    },
    updateAccessToFeatures: (state, { payload }) => {
      if (payload) {
        state.hasAccessToFeatures = payload
      } else {
        state.hasAccessToFeatures = false
      }
    },
  },
})

export default store
