import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { ButtonWrapper } from './Button.elements'

function Button(props) {
  // Destructure
  const { type, size, value, children, onClick, variant, startIcon, endIcon, color, fullWidth, disabled } = props

  return (
    <ButtonWrapper
      type={type}
      size={size}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      color={color}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {value || children}
    </ButtonWrapper>
  )
}

// Default Props
Button.defaultProps = {
  onClick: () => {},
  type: 'primary',
  size: 'medium',
  value: '',
  children: '',
  startIcon: '',
  endIcon: '',
  variant: 'contained',
  color: 'primary',
  fullWidth: false,
  disabled: false,
}

// Proptypes Validation
Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  startIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  endIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  variant: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Button
