/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'

// Constants
import { riskOptions, typeOptions, statusOptions } from 'common/constants/policyScenarioOptions'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
	ScenariosTableItemDetailsWrapper,
	ButtonWrapper,
	RowContainer,
} from './ScenariosTableItemDetails.elements'

// Views
import { Button, Form, FormTextField, FormSelect } from 'views/components'
import { ScenarioBuilder } from 'views/layouts'

function ScenariosTableItemDetails(props) {
  // Destructure
	const {
    queryData,
    scenario_id,
    scenarioName,
    severity,
    type,
    status,
    isExpanded,
    setAccordionDetails,
    currentPolicy,
    setCurrentPolicy,
    scenarios,
    setScenarios,
  } = props

	// Variables
  const initialValues = {
		scenarioName: scenarioName || '',
		severity: severity || '',
		type: type || '',
		status: status || '',
  }
  const validationSchema = Yup.object().shape({})
  const formRef = useRef({})

  // Hooks
  const { policyId } = useParams()
  const [policiesList, setPoliciesList] = useLocalStorage('policies', [])

  // States
  const [formValues] = useState(initialValues)
  const [isReadOnly, setIsReadOnly] = useState(true)
  const [queryDataState, setQueryDataState] = useState(queryData || '')
  const [isSaved, setIsSaved] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false)

  // Functions
  const handleOnSubmit = async () => {
    const { values } = formRef.current
    const scenarioItem = {
			scenario_id,
			scenario_info: {
				...values,
			},
      queryData: queryDataState,
    }
		const newScenarios = scenarios.map((item) => {
			if (item.scenario_id === scenario_id) {
				return { ...scenarioItem }
			}
			return item
		})
    const newCurrentPolicy = { ...currentPolicy, scenarios: newScenarios }

    const newPoliciesList = policiesList.map((item) => {
      if (item.policy_id === policyId) {
        return { ...newCurrentPolicy }
      }
      return item
    })
    setScenarios(newScenarios)
    setCurrentPolicy(newCurrentPolicy)
    setPoliciesList(newPoliciesList)
		setAccordionDetails({ ...values })
    setIsReadOnly(true)
    setIsSaved(true)
  }

  const handleCancel = () => {
    setIsReadOnly(true)
    setIsCancelled(true)
  }

  useEffect(() => {
    const currentPolicy = policiesList.find((item) => item.policy_id === policyId)
    if (currentPolicy) {
      setCurrentPolicy(currentPolicy)
      setScenarios(currentPolicy.scenarios)
    }
  }, [])

	useEffect(() => {
		if (!isExpanded) setIsReadOnly(true)
	}, [isExpanded])

	return (
  <ScenariosTableItemDetailsWrapper>
    <Form
      enableReinitialize
      formRef={formRef}
      initialValues={formValues}
      validationSchema={validationSchema}
      autoComplete="off"
    >
      <RowContainer>
        <FormTextField label="Scenario Name" type="text" name="scenarioName" required disabled={isReadOnly} />
        <FormSelect label="Severity" name="severity" options={riskOptions} required disabled={isReadOnly} />
        <FormSelect label="Type" name="type" options={typeOptions} required disabled={isReadOnly} />
        <FormSelect label="Status" name="status" options={statusOptions} required disabled={isReadOnly} />
      </RowContainer>
      <ScenarioBuilder
        initValue={queryData}
        readOnly={isReadOnly}
        setQueryData={setQueryDataState}
        isCancelled={isCancelled}
        setIsCancelled={setIsCancelled}
        isSaved={isSaved}
        setIsSaved={setIsSaved}
      />
      <ButtonWrapper>
        {!isReadOnly ?
          <>
            <Button type="submit" onClick={handleOnSubmit}>Save</Button>
            <Button type="button" color="secondary" onClick={handleCancel}>Cancel</Button>
          </>
        :
          <Button type="button" onClick={() => { setIsReadOnly(false) }}>Edit</Button>
				}
      </ButtonWrapper>
    </Form>
  </ScenariosTableItemDetailsWrapper>
	)
}

// Default Props
ScenariosTableItemDetails.defaultProps = {
  queryData: {},
	scenario_id: '',
	scenarioName: '',
	severity: '',
	type: '',
	status: '',
	isExpanded: false,
	setAccordionDetails: () => {},
  currentPolicy: {},
  setCurrentPolicy: () => {},
  scenarios: [],
  setScenarios: () => {},
}

// Proptypes Validation
ScenariosTableItemDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryData: PropTypes.object,
	scenario_id: PropTypes.string,
	scenarioName: PropTypes.string,
	severity: PropTypes.string,
	type: PropTypes.string,
	status: PropTypes.string,
	isExpanded: PropTypes.bool,
	setAccordionDetails: PropTypes.func,
  currentPolicy: PropTypes.object,
  setCurrentPolicy: PropTypes.func,
  scenarios: PropTypes.array,
  setScenarios: PropTypes.func,
}

export default ScenariosTableItemDetails
