import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver'

// Assets
import BrandLogo from 'assets/images/brand-logo-white.png'
import UserIcon from 'assets/images/user-white.svg'
import UserCircledIcon from 'assets/images/user-icon.svg'
import LogoutIcon from 'assets/images/log-out.svg'
import MenuIcon from 'assets/images/menu-icon-white.svg'
import TabPaneIcon from 'assets/images/tab-pane-white.svg'
import NotificationIcon from 'assets/images/notification-bell-white.svg'

// Contants
import { EXTERNAL_URLS } from 'common/constants/apiUrls'

// Store
import { actions } from 'core/store'

// Hooks
import { useLogout } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  HeaderWrapper,
  HeaderInner,
  HeaderBrand,
  HeaderBrandLogo,
  HeaderMenuNav,
  HeaderMenuNavList,
  HeaderMenuNavItem,
  HeaderMenuNavLink,
  HeaderMenuNavText,
  HeaderRightNav,
  HeaderRightNavList,
  HeaderRightNavListItem,
  HeaderLogoutButton,
  ProfileInfoWrapper,
  ProfileFullName,
  ProfileUserName,
  ProfileUserRole,
  HeaderSideMenuButton,
  HeaderSideMenuIcon,
  NotificationIconImage,
  MenuIconImage,
  UserIconImage,
  UserCircledIconImage,
  LogoutIconImage,
  SubHeaderWrapper,
  SubHeaderContent,
  DropdownItemLink,
  DropdownItemText,
  DropdownItemDownload,
  HasNotificationIcon
} from './Header.elements'

// Views
import {
  ContainerFluid,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Image,
  LoaderLine,
} from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function Header(props) {
  // Destructure
  const { ui, actions, subHeader } = props

  // Store States
  const { showHeaderLoader, isProfileUpdated, isNotifUpdated } = ui

  // Store Actions
  const { openAppSidebarDrawer, setIsProfileUpdated, setIsNotifUpdated } = actions

  // Hooks
  const { logoutUser } = useLogout()
  const [userCredentials, setUserCredentials] = useLocalStorage('userCredentials', false)
  const [reportStrings, setReportStrings] = useLocalStorage('reportStrings', [])
  const [reportTitles, setReportTitles] = useLocalStorage('reportTitles', [])

  // Functions
  // handling download
  const handleDownloadReport = (index) => {
    saveAs(
      new Blob([s2ab(reportStrings[index])], {
        type: 'application/octet-stream',
      }),
      reportTitles[index]
    );
  }
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  useEffect(() => {
    if (isProfileUpdated) {
      // Updating profile header
      setUserCredentials(JSON.parse(localStorage.getItem('userCredentials')))
      setIsProfileUpdated(false)
    }
    if (isNotifUpdated) {
      // Updating notif bar
      setReportStrings(JSON.parse(localStorage.getItem('reportStrings')))
      setReportTitles(JSON.parse(localStorage.getItem('reportTitles')))
      setIsNotifUpdated(false)
    }
  }, [isProfileUpdated, isNotifUpdated])
  return (
    <HeaderWrapper>
      <LoaderLine show={showHeaderLoader} />
      <ContainerFluid>
        <HeaderInner>
          <HeaderBrand>
            <NavLink to="/">
              <HeaderBrandLogo>
                <Image src={BrandLogo} />
              </HeaderBrandLogo>
            </NavLink>
          </HeaderBrand>
          <HeaderMenuNav>
            <HeaderMenuNavList>
              <HeaderMenuNavItem>
                <HeaderMenuNavLink exact activeClassName="active" to="/">
                  <HeaderMenuNavText>Case Management</HeaderMenuNavText>
                </HeaderMenuNavLink>
              </HeaderMenuNavItem>
              <HeaderMenuNavItem>
                <HeaderMenuNavLink exact to="/whitelisting">
                  <HeaderMenuNavText>Address Monitoring</HeaderMenuNavText>
                </HeaderMenuNavLink>
              </HeaderMenuNavItem>
              <HeaderMenuNavItem>
                <HeaderMenuNavLink exact to="/monitoring">
                  <HeaderMenuNavText>Transaction Monitoring</HeaderMenuNavText>
                </HeaderMenuNavLink>
              </HeaderMenuNavItem>
              <HeaderMenuNavItem>
                <HeaderMenuNavLink exact to="/alerts">
                  <HeaderMenuNavText>Alert Management</HeaderMenuNavText>
                </HeaderMenuNavLink>
              </HeaderMenuNavItem>
              <HeaderMenuNavItem>
                <HeaderMenuNavLink exact to="/travelrule">
                  <HeaderMenuNavText>Travel Rule</HeaderMenuNavText>
                </HeaderMenuNavLink>
              </HeaderMenuNavItem>
            </HeaderMenuNavList>
          </HeaderMenuNav>
          <HeaderRightNav>
            <HeaderRightNavList>
              <HeaderRightNavListItem>
                <UncontrolledDropdown direction="down">
                  <DropdownToggle>
                    <NotificationIconImage>
                      <Image src={NotificationIcon} />
                      {reportTitles?.length > 0 &&
                        <HasNotificationIcon>!</HasNotificationIcon>
                      }
                    </NotificationIconImage>
                  </DropdownToggle>
                  <DropdownMenu left>
                    {reportTitles?.length > 0 ?
                      <>
                        <DropdownItem header>
                          <DropdownItemText>Data import completed, download the report</DropdownItemText>
                        </DropdownItem>
                        {reportTitles.map((item, i) => (
                          <DropdownItem header key={item}>
                            <DropdownItemDownload onClick={() => { handleDownloadReport(i) }}>
                              {item.substring(0, 19)}...
                            </DropdownItemDownload>
                          </DropdownItem>
                        ))}
                      </>
                    :
                      <DropdownItem header>
                        <DropdownItemText>No notifications</DropdownItemText>
                      </DropdownItem>
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </HeaderRightNavListItem>
              <HeaderRightNavListItem>
                <UncontrolledDropdown direction="down">
                  <DropdownToggle>
                    <MenuIconImage>
                      <Image src={MenuIcon} />
                    </MenuIconImage>
                  </DropdownToggle>
                  <DropdownMenu left>
                    <DropdownItem header>
                      <a onClick={() => { window.open(EXTERNAL_URLS.FEEDBACK, '_blank') }}>Send feedback</a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </HeaderRightNavListItem>
              <HeaderRightNavListItem>
                <UncontrolledDropdown direction="down">
                  <DropdownToggle>
                    <UserIconImage>
                      <Image src={UserIcon} />
                    </UserIconImage>
                  </DropdownToggle>
                  <DropdownMenu left>
                    <ProfileInfoWrapper>
                      <ProfileFullName>{userCredentials.FullName}</ProfileFullName>
                      <ProfileUserName>{userCredentials.User_Name}</ProfileUserName>
                      <ProfileUserRole>{userCredentials.User_Role}</ProfileUserRole>
                    </ProfileInfoWrapper>
                    <DropdownItem header>
                      <DropdownItemLink to="/user-profile">
                        <UserCircledIconImage>
                          <Image src={UserCircledIcon} height={20} width={20} />
                        </UserCircledIconImage>
                        Profile
                      </DropdownItemLink>
                    </DropdownItem>
                    <DropdownItem header onClick={() => { logoutUser() }}>
                      <HeaderLogoutButton onClick={() => { logoutUser() }}>
                        <LogoutIconImage>
                          <Image src={LogoutIcon} height={20} width={20} />
                        </LogoutIconImage>
                        Logout
                      </HeaderLogoutButton>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </HeaderRightNavListItem>
            </HeaderRightNavList>
          </HeaderRightNav>
          <HeaderSideMenuButton>
            <HeaderSideMenuIcon onClick={() => { openAppSidebarDrawer() }}>
              <Image src={TabPaneIcon} />
            </HeaderSideMenuIcon>
          </HeaderSideMenuButton>
        </HeaderInner>
      </ContainerFluid>
      {subHeader && (
        <SubHeaderWrapper>
          <ContainerFluid>
            <SubHeaderContent>{subHeader}</SubHeaderContent>
          </ContainerFluid>
        </SubHeaderWrapper>
      )}
    </HeaderWrapper>
  )
}

// Default Props
Header.defaultProps = {
  subHeader: '',
  ui: {},
  actions: {},
}

// Proptypes Validation
Header.propTypes = {
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  ui: PropTypes.shape({
    showHeaderLoader: PropTypes.bool,
    isProfileUpdated: PropTypes.bool,
    isNotifUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    openAppSidebarDrawer: PropTypes.func,
    setIsProfileUpdated: PropTypes.func,
    setIsNotifUpdated: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
