import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { SidebarWrapper } from './Sidebar.elements'

function Sidebar(props) {
  // Destructure
  const { children } = props

  return <SidebarWrapper>{children}</SidebarWrapper>
}

// Default Props
Sidebar.defaultProps = {
  children: '',
}

// Proptypes Validation
Sidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default Sidebar
