import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'

// Library Components
import { AccordionItem, AccordionItemHeading } from 'react-accessible-accordion'

// Assets
import ChevronDownIcon from 'assets/images/icon-chevron-down.svg'

// Styled Elements
import {
  AccordionItemButtonWrapper,
  AccordionItemButtonInnerWrapper,
  AccordionItemButtonInner,
  AccordionItemButtonExpandIcon,
  AccordionItemPanelWrapper,
} from './AccordionTableItem.elements'

// Views
import { Image } from 'views/components'

function AccordionTableItem(props) {
  // Destructure
  const { button, panel, withExpandIcon, expandIcon, setIsExpanded, expandPanel } = props

  // Functions
  const handleExpand = (event) => {
    if (event.target.closest('[data-for-toggle]')) {
      return
    }
    setIsExpanded((prevState) => !prevState)
  }

  return (
    <AccordionItem dangerouslySetExpanded={expandPanel}>
      <AccordionItemHeading onClick={handleExpand}>
        <AccordionItemButtonWrapper>
          <AccordionItemButtonInnerWrapper>
            <AccordionItemButtonInner>{button({ expanded: expandPanel })}</AccordionItemButtonInner>
            <AccordionItemButtonExpandIcon withExpandIcon={withExpandIcon ? 1 : 0} expanded={expandPanel ? 1 : 0}>
              {expandIcon || <Image src={ChevronDownIcon} />}
            </AccordionItemButtonExpandIcon>
          </AccordionItemButtonInnerWrapper>
        </AccordionItemButtonWrapper>
      </AccordionItemHeading>
      <AccordionItemPanelWrapper expanded={expandPanel ? 1 : 0}>
        {cloneElement(panel, { expanded: expandPanel })}
      </AccordionItemPanelWrapper>
    </AccordionItem>
  )
}

// Default Props
AccordionTableItem.defaultProps = {
  button: '',
  panel: '',
  withExpandIcon: true,
  expandIcon: '',
  expandPanel: false
}

// Proptypes Validation
AccordionTableItem.propTypes = {
  button: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element, PropTypes.node]),
  panel: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element, PropTypes.node]),
  withExpandIcon: PropTypes.bool,
  expandIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  setIsExpanded: PropTypes.func,
  expandPanel: PropTypes.bool
}

export default AccordionTableItem
