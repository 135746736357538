import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Library Components
import { Space } from 'antd'

// Assets
import FilterIcon from 'assets/images/filter.svg'
import ArrowDownIcon from 'assets/images/arrow-down.svg'

// Styled Elements
import {
  AccordionTableWrapper,
  AccordionTableHeaderWrapper,
  AccordionHeaderTitleWrapper,
  AccordionHeaderIcon,
  AccordionHeaderTitle,
  AccordionHeaderFilterButton,
  AccordionFilterWrapper,
  AccordionTableBodyOverflowWrapper,
  AccordionTableBodyChildrenWrapper,
  AccordionTableBodyWrapper,
  AccordionTableBodyHeaderWrapper,
  AccordionTableBodyHeaderItem,
  AccordionTableBodyHeaderText,
  AccordionTableBodySubHeaderText,
  AccordionTableBodyHeaderIcon,
  AccordionHeaderOptionsWrapper,
} from './AccordionTable.elements'

// Views
import { Image, IconButton, Pagination } from 'views/components'

// TODO: Use global state of filters
function AccordionTable(props) {
  // Destructure
  const {
    title,
    titleIcon,
    headers,
    headerOptions,
    options,
    children,
    minWidth,
    totalItems,
    filterComponents,
    tableFilters,
    setTableFilters,
  } = props

  // States
  const [collapseFilter, setCollapseFilter] = useState(true)
  const { sortDirection, sortBy, pageSize, pageIndex } = tableFilters

  // Variables
  const withHeader = headers.length
  const isHeaderSortActive = ({ identifier }) => identifier === sortBy

  // Functions
  const handleCollapseFilter = () => {
    setCollapseFilter((prevState) => !prevState)
  }

  const handleSort = async (args) => {
    const { identifier } = args

    const sortDirectionToggle = sortDirection === 'DESC' ? 'ASC' : 'DESC'

    if (sortBy !== identifier) {
      await setTableFilters({ ...tableFilters, sortBy: identifier, sortDirection: 'DESC' })
    } else {
      await setTableFilters({ ...tableFilters, sortDirection: sortDirectionToggle })
    }
  }

  const handlePageChange = async ({ pageIndex, pageSize }) => {
    await setTableFilters({ ...tableFilters, pageIndex, pageSize })
  }

  return (
    <>
      <AccordionTableWrapper allowZeroExpanded>
        <AccordionTableHeaderWrapper>
          <AccordionHeaderTitleWrapper>
            <AccordionHeaderIcon>{titleIcon}</AccordionHeaderIcon>
            <AccordionHeaderTitle>{title}</AccordionHeaderTitle>
          </AccordionHeaderTitleWrapper>
          <AccordionHeaderFilterButton>
            <Space>
              <IconButton onClick={handleCollapseFilter}>
                <Image src={FilterIcon} width={20} height={20} />
              </IconButton>
              {headerOptions}
            </Space>
          </AccordionHeaderFilterButton>
          <AccordionHeaderOptionsWrapper>{options}</AccordionHeaderOptionsWrapper>
        </AccordionTableHeaderWrapper>
        <AccordionFilterWrapper collapseFilter={collapseFilter} withFilter={filterComponents}>
          {filterComponents}
        </AccordionFilterWrapper>
        <AccordionTableBodyOverflowWrapper>
          <AccordionTableBodyWrapper minWidth={minWidth}>
            <AccordionTableBodyHeaderWrapper showHeader={withHeader}>
              {headers.map(({ name, subHeader, sort, identifier, width }) => (
                <AccordionTableBodyHeaderItem
                  key={name}
                  sortable={sort}
                  width={width}
                  onClick={() => sort && handleSort({ identifier })}
                >
                  <AccordionTableBodyHeaderText>
                    {name}
                    {subHeader && <AccordionTableBodySubHeaderText>{subHeader}</AccordionTableBodySubHeaderText>}
                  </AccordionTableBodyHeaderText>

                  {sort && (
                    <AccordionTableBodyHeaderIcon
                      sortDirection={sortDirection}
                      active={isHeaderSortActive({ identifier })}
                    >
                      <Image src={ArrowDownIcon} width={18} height={18} />
                    </AccordionTableBodyHeaderIcon>
                  )}
                </AccordionTableBodyHeaderItem>
              ))}
            </AccordionTableBodyHeaderWrapper>
            <AccordionTableBodyChildrenWrapper>
              {children}
            </AccordionTableBodyChildrenWrapper>
          </AccordionTableBodyWrapper>
        </AccordionTableBodyOverflowWrapper>
      </AccordionTableWrapper>
      <Pagination
        itemsCountPerPage={pageSize}
        totalItemsCount={totalItems}
        pageIndex={pageIndex}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
      />
    </>
  )
}

// Default Props
AccordionTable.defaultProps = {
  title: '',
  titleIcon: '',
  headers: [],
  headerOptions: '',
  options: '',
  children: '',
  minWidth: 0,
  totalItems: 0,
  filterComponents: '',
  tableFilters: {},
  setTableFilters: () => {}
}

// Proptypes Validation
AccordionTable.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  headers: PropTypes.instanceOf(Array),
  headerOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  options: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  minWidth: PropTypes.number,
  totalItems: PropTypes.number,
  filterComponents: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  tableFilters: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  setTableFilters: PropTypes.func
}

export default AccordionTable
