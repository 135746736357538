import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import {
  useFetchAutoCompleteCustomer,
  useAssignMonitoringTransaction,
  useAssignWhitelistingAddress,
} from 'core/hooks/api'

// Styled Elements
import { AssignToCaseFormWrapper, FormTitle, FormErrorText, FormInputGroupItem } from './AssignToCaseForm.elements'

// Views
import { Form, FormTextField, FormAutoComplete } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AssignToCaseForm(props) {
  // Destructure
  const { formRef, form, actions } = props

  // Store State
  const { addressOrTransactionIdToAssign } = form

  // Store Actions
  const { setShowHeaderLoader, closeAssignToCaseDrawer, showAlert, setAddressOrTransactionIdToAssign } = actions

  // Hooks
  const {
    autoCompleteCustomerData,
    getAutoCompleteCustomer,
    isAutoCompleteCustomerLoading
  } = useFetchAutoCompleteCustomer()
  const {
    assignMonitoringTransaction,
    isAssignMonitoringTransactionLoading,
    isAssignMonitoringTransactionSuccess,
    isAssignMonitoringTransactionError,
  } = useAssignMonitoringTransaction()
  const {
    assignWhitelistingAddress,
    isAssignWhitelistingAddressLoading,
    isAssignWhitelistingAddressSuccess,
    isAssignWhitelistingAddressError,
  } = useAssignWhitelistingAddress()

  // Variables
  const initialValues = {
    caseType: '',
    caseStatus: '',
    caseSince: '',
    caseId: ''
  }
  const validationSchema = Yup.object().shape({})
  const isLoading = (
    isAssignMonitoringTransactionLoading || isAssignWhitelistingAddressLoading || isAutoCompleteCustomerLoading
  )
  const notLoading = (
    !isAssignMonitoringTransactionLoading &&
    !isAssignWhitelistingAddressLoading &&
    !isAutoCompleteCustomerLoading
  )

  // States
  const [formValues, setFormValues] = useState(initialValues)
  const [customerSearchOptions, setCustomerSearchOptions] = useState([])
  const [searchedCustomerList, setSearchedCustomerList] = useState([])
  const [errmsg, setErrMsg] = useState(null)

  // Functions
  const handleOnSubmit = (values) => {
    if (isAutoCompleteCustomerLoading || !values) {
      return setErrMsg('An error occured, please try again')
    }
    setErrMsg(null)
    const { caseId, addressOrTransactionId, addressOrTransactionType } = values

    const payload = {
      addressOrTransactionId,
      case_id: caseId,
    }

    if (addressOrTransactionType === 'transaction') {
      assignMonitoringTransaction(payload)
    } else if (addressOrTransactionType === 'address') {
      assignWhitelistingAddress(payload)
    }
  }

  const handleOnAutoCompleteSelect = (event) => {
    setErrMsg(null)
    const customerID = event.target.value

    const customerData = searchedCustomerList.filter(({ Customer_ID }) => Customer_ID === customerID)[0]

    if (customerData) {
      setFormValues({
        addressOrTransactionType: addressOrTransactionIdToAssign.type,
        addressOrTransactionId: addressOrTransactionIdToAssign.id,
        caseId: customerData.Case_ID,
        caseType: customerData.Case_Type,
        caseStatus: customerData.Case_Status,
        caseSince: customerData.Case_Since,
      })
    }
  }

  const handleOnAutoCompleteInputChange = debounce((e) => {
    setErrMsg(null)
    if (e) {
      const queryValue = e.target.value

      if (queryValue) getAutoCompleteCustomer({ customer_id: queryValue })
    }
  }, 500)

  // useEffect
  useEffect(() => {
    if (autoCompleteCustomerData) {
      setSearchedCustomerList(() => autoCompleteCustomerData)
      setCustomerSearchOptions(() =>
        autoCompleteCustomerData.map(({ Customer_ID }) => ({ label: Customer_ID, value: Customer_ID }))
      )
    }
  }, [autoCompleteCustomerData])

  useEffect(() => {
    if (isLoading) {
      setShowHeaderLoader(true)
    } else if (notLoading) {
        if (isAssignMonitoringTransactionSuccess || isAssignWhitelistingAddressSuccess) {
          setAddressOrTransactionIdToAssign({
            type: addressOrTransactionIdToAssign.type,
            id: addressOrTransactionIdToAssign.id,
            caseId: formValues.caseId,
          })
          setShowHeaderLoader(false)
          closeAssignToCaseDrawer()
          showAlert({ type: 'success', message: 'Successfully assigned to case' })
        } else if (isAssignMonitoringTransactionError || isAssignWhitelistingAddressError) {
          return setErrMsg('An error occured, please try again later')
        } else { setShowHeaderLoader(false) }
      }
  }, [isAssignMonitoringTransactionLoading, isAssignWhitelistingAddressLoading, isAutoCompleteCustomerLoading])

  return (
    <AssignToCaseFormWrapper>
      {errmsg &&
        <FormErrorText>{errmsg}</FormErrorText>
      }
      <FormTitle>Search Customer ID</FormTitle>
      <Form
        enableReinitialize
        formRef={formRef}
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        autoComplete="off"
      >
        <FormInputGroupItem>
          <FormAutoComplete
            name="customerId"
            placeholder="Type to search"
            options={customerSearchOptions}
            onInputChange={handleOnAutoCompleteInputChange}
            onSelect={handleOnAutoCompleteSelect}
          />
        </FormInputGroupItem>
        <FormInputGroupItem>
          <FormTextField placeholder="Type" type="text" name="caseType" disabled />
        </FormInputGroupItem>
        <FormInputGroupItem>
          <FormTextField placeholder="Status" type="text" name="caseStatus" disabled />
        </FormInputGroupItem>
        <FormInputGroupItem>
          <FormTextField placeholder="Case Since" type="text" name="caseSince" disabled />
        </FormInputGroupItem>
      </Form>
    </AssignToCaseFormWrapper>
  )
}

// Default Props
AssignToCaseForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
}

// Proptypes Validation
AssignToCaseForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({ addressOrTransactionIdToAssign: PropTypes.instanceOf(Object) }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    closeAssignToCaseDrawer: PropTypes.func,
    showAlert: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setAddressOrTransactionIdToAssign: PropTypes.func
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignToCaseForm)
